import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { SizeType } from '@cf/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'div[cf-main-logo], a[cf-main-logo]',
  templateUrl: './main-logo.component.html',
  styleUrls: ['./main-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MainLogoComponent {
  @HostBinding('class') classes = 'main-logo';

  @Input() logoHeight!: number | string;

  @Input() size: SizeType = 'md';
}
